import { useEffect, useContext } from 'react';
import { useRollbar } from '@rollbar/react';
import {
  APP_ACTIONS,
  AppContext,
  USER_AUTH_LIFECYCLE_ACTIONS,
} from './appContext';
import { FETCH_ACTIONS } from './Fetcher';
import { notificationsHandler } from './notifications/NotificationsManager';
import { useQueryClient } from 'react-query';
import { QUERIES_KEYS } from './wilco-engine/queries/studio/queries';
import { USER_QUERIES_KEYS } from './wilco-engine/queries/user';
import { useHistory, useLocation } from 'react-router-dom';

const CLIENT_EVENTS = {
  QUEST_FINISHED: 'quest_finished',
  LEVEL_STARTED: 'level_started',
  CREDITS_UPDATED: 'credits_updated',
  USER_UPDATED: 'user_updated',
  USER_AUTHORED_QUESTS_UPDATED: 'user_authored_quests_updated',
  QUEST_GENERATION_STARTED: 'quest_generation_started',
  QUEST_GENERATION_FINISHED: 'quest_generation_finished',
  MOVE_TO_URL: 'move_to_url',
  GITHUB_CODESPACE_STARTED: 'github_codespace_started',
};

const eventsHandler = (payload, dispatch, queryClient, history, location) => {
  switch (payload.event) {
    case CLIENT_EVENTS.LEVEL_STARTED:
      queryClient.refetchQueries(USER_QUERIES_KEYS.USER_QUESTS);
      break;
    case CLIENT_EVENTS.QUEST_FINISHED:
      queryClient.refetchQueries(USER_QUERIES_KEYS.USER_QUESTS);
      break;
    case CLIENT_EVENTS.CREDITS_UPDATED:
      dispatch({
        type: USER_AUTH_LIFECYCLE_ACTIONS.UPDATE_USER,
        payload: payload.data,
      });
      break;
    case CLIENT_EVENTS.USER_UPDATED:
      dispatch({
        type: USER_AUTH_LIFECYCLE_ACTIONS.UPDATE_USER,
        payload: payload.data,
      });
      break;
    case CLIENT_EVENTS.USER_AUTHORED_QUESTS_UPDATED:
      dispatch({
        type: APP_ACTIONS.FETCH,
        payload: { action: FETCH_ACTIONS.FETCH_USER_AUTHORED_QUESTS },
      });
      break;
    case CLIENT_EVENTS.QUEST_GENERATION_STARTED:
      queryClient.refetchQueries(QUERIES_KEYS.GENERATED_QUESTS);
      break;
    case CLIENT_EVENTS.QUEST_GENERATION_FINISHED:
      queryClient.refetchQueries(QUERIES_KEYS.GENERATED_QUESTS);
      queryClient.refetchQueries(USER_QUERIES_KEYS.MY_AUTHORED_QUESTS);
      if (
        payload.data.success &&
        payload.data.questPrimaryId &&
        location.pathname === '/studio'
      ) {
        history.push(`/explore/quest/${payload.data.questPrimaryId}`);
      }
      break;
    case CLIENT_EVENTS.MOVE_TO_URL: {
      const url = payload.data.url;
      try {
        new URL(url);
        // If we reach this line, the URL is absolute
        window.location.href = url;
      } catch {
        // If we reach this line, the URL is not absolute
        history.push(url);
      }
      break;
    }
    case CLIENT_EVENTS.GITHUB_CODESPACE_STARTED:
      queryClient.refetchQueries(USER_QUERIES_KEYS.CODESPACE_STATUS);
      break;
    default:
      throw new Error(`Unknown server event: ${payload.event}`);
  }
};

function ServerEvents() {
  const rollbar = useRollbar();
  const history = useHistory();
  const location = useLocation();

  const {
    dispatch,
    state: { user, socket },
  } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!user?.id || !socket) {
      return;
    }
    socket.on('server_message', data => {
      try {
        eventsHandler(data, dispatch, queryClient, history, location);
      } catch (e) {
        rollbar.warning(e);
      }
    });

    socket.on('notifications', notification => {
      try {
        notificationsHandler(dispatch, notification);
      } catch (e) {
        rollbar.warning(e);
      }
    });
  }, [dispatch, user?.id, socket, rollbar, queryClient, history, location]);

  return null;
}

export default ServerEvents;
