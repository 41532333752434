const translations = {
  chat: {
    conversation: {
      followInstructions: 'עקבו אחרי ההוראות של {{name}}',
      awaitingUserMessage: 'מחכה לתשובתכם',
      awaitingPR: 'מחכה שתטפלו ב-PR',
      awaitingChatForm: 'מחכה לתשובתכם',
      connectToGithub: 'התחברו לגיט',
      connectToWix: 'התחברו לוויקס',
      stuck: ' 🙋 תקועים? אל תהססו, ',
      contactSupport: 'צרו קשר עם התמיכה',
      isTyping: 'כותב/ת',

      marker: {
        questStarted: 'התחלה',
        questFinished: 'סיום',
        questStopped: 'נעצר',
        questContinuing: 'נמשך',
        newMessage: 'חדש',
      },
      forms: {
        multiSelect: {
          selected: `נבחר: {{selected}}`,
        },
        singleSelect: {
          selected: `נבחר: {{selected}}`,
        },
        secretInput: {
          empty: 'לא יכול להיות ריק',
        },
      },
      welcomeExperience: {
        title: 'ברוכים הבאים ל',
        body:
          'ווילקו צ׳אט זוהי פלטפורמת ההודעות שלנו\n' +
          'המקום בו תוכל להכיר את חברי הצוות הווירטואליים שלך ולהתחיל את חווית הלמידה שלך בווילקו.',
        ready: 'מוכנים?',
        button: 'התחל במשימה!',
      },
      message: {
        new: 'חדש',
        aiGenerated: 'נוצר ע״י AI',
        ts: 'H:mm',
        tsNoMeridiem: 'h:mm',
        tsFormat: 'MMM Do [ב] LT',
      },
      getHint: 'בקשת רמז',
      getSolution: 'בקשת פתרון',
    },
    header: {
      elapsedTime: {
        tooltip:
          'הזמן שחלף מאז שהמשימה החלה. הוא משתנה לאדום אם הוא חורג מהזמן הממוצע',
      },
      notVerifiedQuest: 'משימה זו לא אומתה על ידי ווילקו',
    },
    notifications: {
      achievement: 'הישג נרשם',
    },
    sidebar: {
      backToWilco: 'חזרה לווילקו',
      signup: 'הרשמה לווילקו',
      replayQuest: 'התחל מחדש את המשימה',
      wilcoChat: 'ווילקו צ׳אט',
      codespace: {
        title: 'קודספייס',
        text: 'קודספייס',
        statusText: {
          online: 'מחובר',
          idle: 'עצר',
          offline: 'מנותק',
        },
        tooltip:
          'קודספייס הוא סביבת פיתוח מבוססת ענן שמאפשרת לך לפתח, לבדוק ולהפעיל את הקוד שלך מתוך דפדפן האינטרנט שלך',
      },
      kubernetes: {
        title: 'Kubernetes',
        download: 'הורד Kubeconfig',
      },
      reportIssue: {
        text: 'דווח על בעיה',
        subject: 'דיווח על בעיה במשימה: {{primaryId}} מזהה: {{id}}',
        body: 'שלום,\n\n יש לי בעיה במשימה {{primaryId}} עם המזהה {{id}}',
      },
      userTools: {
        title: 'כלים',
        github: 'GitHub Repository',
        newRelic: 'New Relic Instance',
        mongoAtlas: 'MongoDB Atlas Instance',
        circleCi: 'CircleCI Instance',
        permit: 'Permit.io Instance',
      },
      friends: {
        title: 'הודעות',
      },
      help: {
        title: 'עזרה',
      },
    },
    questInfoBar: {
      about: 'מידע על המשימה',
      timeEstimate: 'זמן מוערך',
      hours: 'שעות',
      hour: 'שעה',
      step: 'שלב',
      questProgress: {
        heading: 'התקדמות במשימה',
        completed: 'הושלם',
      },
      jumpToStep: 'קפוץ לתחילת השלב >',
    },
  },
  modals: {
    replay: {
      header: 'התחל מחדש את המשימה',
      switch:
        'אם תבחרו לשנות את המשימה הנוכחית, התקדמות המשימה הנוכחית שלכם לא תישמר.\nכדי לחזור למשימה זו תצטרכו להתחיל אותה מחדש.\nהאם אתם רוצים להמשיך?',
      restart:
        'אם תבחרו לשחק מחדש או להתחיל מחדש את המשימה הזו, לא תרוויחו כל ווילקוינים והישגים שכבר הרווחתם במשימה זו',
      button: 'התחל מחדש',
    },
  },
};

export default translations;
